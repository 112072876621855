<template>
  <v-container fluid>
    <v-form v-model="valida" dense>
      <v-row>
        <v-col cols="12" class="pb-0">
          <label> {{ $t("components.views.settings.counteroffer.please_enter_the_percentage_to_modify") }}</label>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="5">
          <v-text-field
            v-model="percent"
            v-integer
            :label="$t('components.views.settings.counteroffer.percent')"
            :disabled="!state"
            required
            outlined
            dense
            maxlength="3"
            append-icon="fa-solid fa-percent"
            name="usuario"
            :rules="[required($t('components.views.settings.counteroffer.percent'), 0), maxLength($t('components.views.settings.percent'), 100)]"
            block
          />
        </v-col>
      </v-row>
    </v-form>
    <v-divider class="secondary" />
    <v-row>
      <v-col cols="12" class="pt-10 pb-0 px-10" justify="justify">
        <label class="silver--text">
          <p class="text-justify" v-html="descriptionCounterOffer.outerHTML" />
        </label>
      </v-col>
    </v-row>
    <v-card-actions class="border-top my-0">
      <v-spacer />
      <v-btn
        color="light-green darken-3"
        depressed
        @click.stop="saveCounterOffer()"
        class="white--text"
        :disabled="!valida"
      >
        <v-icon small class="fa-solid fa-bookmark mr-1" />
        {{ $t("components.views.settings.counteroffer.save") }}
      </v-btn>
    </v-card-actions>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import validaciones from "@/plugins/validate";
export default {
  data() {
    return {
      ...validaciones,
      percent: "",
      id: 0,
      state: false,
      valida: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    idCompany() {
      return this.unlockUser.user.idCompany;
    },
    unlockUser() {
      return JSON.parse(this.user);
    },
    typeUser() {
      return this.unlockUser.user.typeUser;
    },
    descriptionCounterOffer() {
      let parser = new DOMParser();
      let doc = parser.parseFromString(this.$t("components.views.settings.counteroffer.description"), "text/html");
      return doc.body;
    },
  },
  methods: {
    ...mapActions("snackbar", ["showSnack"]),
    async loadCounterOffer() {
      await this.axios
        .get(`/CounterOffer/GetByIdCompanyAsync`)
        .then((data) => {
          this.percent = data.data.data.porcentage;
          this.id = data.data.data.id;
        })
        .catch((error) => {});
      this.state = true;
    },
    async saveCounterOffer() {
      //Crea nuevo registro
      let porcentage = parseInt(this.percent);
      await this.axios
        .post(`/CounterOffer/Save/${porcentage}`)
        .then(() => {
          this.showSnack({
            text: this.$t("components.views.settings.counteroffer.counter_offer_update_success"),
            title: this.$t("components.views.settings.counteroffer.success"),
            name: "success",
          });
        })
        .catch((error) => {
          this.showSnack({
            text: error.response.data,
            title: this.$t("components.views.settings.counteroffer.counter_offer_update_error"),
            name: "error",
          });
        });
    },
  },
  metaInfo() {
    return {
      title: this.$t("views.settings.counteroffer"),
    };
  },
  mounted() {
    this.loadCounterOffer();
  },
};
</script>

<style scoped>

.title-card,
.title-card >>> .fa-solid {
  color: #fff !important;
}
</style>
